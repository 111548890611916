import {
	HStack,
	Link,
	Button,
	Image
} from '@chakra-ui/react'

import { motion } from 'framer-motion'
import { MobileHeader } from './MobileHeader'
import { DefaultHeader } from './DefaultHeader'

const headerHeight = '100px'

export const Header = () => {
		
	const onMobile = () => window.innerWidth < 768;

	if(onMobile())
		return <MobileHeader />
		else return <DefaultHeader />
}
