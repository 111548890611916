
import {
	Box
} from '@chakra-ui/react'

import { motion } from 'framer-motion'

import { Blobs } from './Blobs'
import { MintComponent } from './MintComponent'

export const MintPage = () => {

	return (
		<Box style={{ overflow: 'hidden' }}>
			<Blobs />
			<MintComponent />
		</Box>
	)
}
