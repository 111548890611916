import { useState, useEffect } from 'react'

import { ethers } from 'ethers'

import {
	VStack,
	Image,
	Slider,
	SliderTrack,
  	SliderFilledTrack,
  	SliderThumb,
  	SliderMark,
	Text,
	Flex,
	Spacer,
	Button,
	Box,
	useToast,
	useDisclosure
} from '@chakra-ui/react'

import { motion } from 'framer-motion'

import { AmountText } from './AmountText'
import { MintButton } from './MintButton'
import { ImgLink } from './ImgLink'
import { Bill } from './Bill'
import { InfoModal } from './InfoModal'

// UNCOUPLED LOGIC ---------------------------------------
const toastStyle = {
	duration: 9000,
	isClosable: true,
	variant: 'none',
	containerStyle: {
		color: 'black',
		bg: 'yellow'
	}
}

export const mintAbi = [
	'function mint(uint8 _mintAmount) public payable',
	'function price() public view returns (uint256)',
	'function whitelistMintPrice() public view returns (uint256)',
	'function whitelistSpotsAvailable(address addr) external view returns (uint256)'
]

// In ETH, hardcoded
const wlPrice = 0;
const price = 5;

export const contractAddr = '0x0a6FbA391364fdc27Cb320b6eDAC48f0f52aE533'
export const getContract = (provider, abi = mintAbi) => {
	return new ethers.Contract(
		contractAddr,
		abi,
		provider.getSigner()
	)
}

const askToInstallMetamask = (toast) => {
	toast({
		title: 'No Metamask :(',
		description: 'Install Metamask noob!!1',
		status: 'error',
		...toastStyle
	})	
}

export const getProvider = () => {
	try {
		return new ethers.providers.Web3Provider(window.ethereum)
	} catch (e) {
		return null
	}
}

const updateIfItsConnected = async (provider, setter) => {
	provider.send('wallet_getPermissions').then(
		res => setter(res.length > 0)
	).catch()
}

// MATIC network
const checkRightNetwork = async (provider) => 
	provider == null ? false :
		(await provider.getNetwork()).chainId === 137

// Only if connected
const askChangeNetIfNeeded = async (provider, setter) => {
	if (await checkRightNetwork(provider)) return setter(true)
	provider.send('wallet_switchEthereumChain', [{ chainId: '0x89' }]).then(
		setter(true)
	).catch(err => err.code === 4001 ? setter(false) : null)
}
// UNCOUPLED LOGIC ---------------------------------------

export const MintComponent = () => {

	const defaultSliderVal = 5
	const [sliderVal, setSliderVal] = useState(defaultSliderVal)
	
	// UNCOUPLED LOGIC ---------------------------------------
	const provider = getProvider()
	const [isConnected, setIsConnected] = useState(false)
	const [currentAddress, setCurrentAddress] = useState(null)
	const [isRightNetwork, setIsRightNetwork] = useState(false)

	const [buttonText, setButtonText] = useState('CONNECT')
	
	const toast = useToast()
	
	useEffect(() => {
		updateIfItsConnected(provider, setIsConnected)
		getAddress()
	}, [])

	useEffect(() => {
		getAddress()
		if (isConnected)
			askChangeNetIfNeeded(provider, setIsRightNetwork)
	}, [isConnected])
	
	
	const changeNet = async () => await askChangeNetIfNeeded(
		provider, setIsRightNetwork
	)

	const updateState = () => {
		updateIfItsConnected(provider, setIsConnected)
	}
	
	useEffect(() => {
		if (!isConnected) setButtonText('CONNECT')
		else if (!isRightNetwork) setButtonText('CHANGE NETWORK')
		else setButtonText('MINT')
	}, [isConnected, isRightNetwork])
	
	const getAddress = async () => {
		if (isConnected)
			setCurrentAddress(await provider.getSigner().getAddress())
		else setCurrentAddress(null)
	}

	const askConnection = async () => {
		return await provider.send('eth_requestAccounts').then(() => {
			setIsConnected(true)
		}).catch(err => err.code === 4001 ? setIsConnected(false) : null)
	}
	
	const { isOpen, onOpen, onClose } = useDisclosure()

	const buttonAction = async () => {
		if (!isConnected) return await askConnection()
		if (!isRightNetwork) return await changeNet()
		mint()
	}
	
	const mint = async () => {
		const contract = getContract(provider)
		const price = (await contract.price())
		const wlPrice = (await contract.whitelistMintPrice())
		const wlSpots = (await contract.whitelistSpotsAvailable(currentAddress)).toNumber()
		var amountToPay
		if (wlSpots >= sliderVal)
			amountToPay = wlPrice.mul(sliderVal)
		else
			amountToPay = price.mul(sliderVal - wlSpots).add(wlPrice.mul(wlSpots))
		try {
			contract.mint(ethers.utils.hexlify(sliderVal), {value: amountToPay}).then(
				res => {console.log(res); onOpen()}
			).carch(
				err => console.log(err)
			)
		} catch (e) {
			toast({
				title: 'ERROR :(',
				description: e.data.message,
				status: 'error',
				...toastStyle
			})	
		}
	}

	// END UNCOUPLED LOGIC ---------------------------------------

	return (
		<VStack 
			h='100vh'
			justifyContent='center'
			bgColor='black'
			style={{
				overflow: 'hidden'
			}}
		>
			<VStack
				w={['60%','55%','45%','35%','30%','20%']}
				zIndex={1}
			>
				<ImgLink />
				<Bill sliderVal={sliderVal} isConnected={isConnected} />
				<Slider 
					aria-label='slider-ex-1'
					defaultValue={defaultSliderVal}
					min={1}
					max={100}
					onChange={setSliderVal}
				>
					<SliderTrack bgColor='#b2c3d5' >
						<SliderFilledTrack bgColor='yellow' />
					</SliderTrack>
					<SliderThumb />
				</Slider>
				<AmountText />
				<MintButton buttonAction={buttonAction} buttonText={buttonText} />
			</VStack>
			<InfoModal isOpen={isOpen} onClose={onClose} />
		</VStack>
	)
}
