
import {
	Image
} from '@chakra-ui/react'

import img1 from './samples/1.png'
import img2 from './samples/2.png'
import img3 from './samples/3.png'
import img4 from './samples/4.png'
import img5 from './samples/5.png'
import img6 from './samples/6.png'
import img7 from './samples/7.png'
import img8 from './samples/8.png'
import img9 from './samples/9.png'
import img10 from './samples/10.png'
import img11 from './samples/11.png'
import img12 from './samples/12.png'
import img13 from './samples/13.png'

import Marquee from 'react-fast-marquee'

const imgs = [
	img1, img2, img3, img4, img5,
	img6, img7, img8, img9, img10,
	img11, img12, img13
]

const repeat = (s, n) => [...Array(n).keys()].map(_ => s)

function shuffle(arr) {
	const array = [...arr]
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
	return array
}

export const Stripe = ({ reverse = false }) => {

	const imgsStack = repeat(shuffle(imgs), 2).flat().map(img => (
		<Image 
			h='150px'
			src={img}
		/>
	))

	return (
		<Marquee
			//pauseOnHover
			gradient={false}
			speed={window.innerWidth/40}
			direction={reverse ? 'right' : 'left'}
			style={{
				overflow: 'hidden',
				backgroundColor: 'red',
			}}
			zIndex={-100}
		>
			{imgsStack}
		</Marquee>
	)
}
