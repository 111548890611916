import { 
	Image,
	Link
} from '@chakra-ui/react'

import { motion } from 'framer-motion'

export const ImgLink = () => (
	<Link href='https://pepelambo.club'>
		<Image 
			as={motion.img}
			src='/resources/unrevealed.gif'
			w='100%'
			whileHover={{ scale: 0.95 }}
			whileTap={{ scale: 1 }}
		/>
	</Link>
)
