
import { 
	VStack,
	HStack,
	Heading,
	Icon,
	Box,
	Text,
	Link,
} from '@chakra-ui/react'

import { LinkButtons } from './LinkButtons'


export const Links = () => {
	
	return (
		<VStack
			h='33vh'
			w='full'
			justifyContent='center'
		>
			<Heading
				size='4xl'
				color='black'
				bgColor='yellow'
				p={5}
				style={{
					boxShadow: '10px 10px 0 #b2c3d5'
				}}
			>
				LINKS
			</Heading>
			<LinkButtons />
		</VStack>

	)
}
