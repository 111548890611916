
import {
	VStack,
	Heading,
	Text,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon
} from '@chakra-ui/react'

import {
	headingBreaks,
	subHeadingBreaks
} from './CollectionInfo'

import { motion } from 'framer-motion'

export const Roadmap = () => {
	
	const headStyle = {
		align: 'left',
		fontSize: subHeadingBreaks,
		color: 'yellow',
		w: 'full',
		as: motion.div,
		variants: { hovering: { x: -10 } }
	}

	const itemStyle = {
		style: { borderTop: 'none', borderBottom: 'none' },
		as: motion.div,
		whileHover: "hovering"
	}

	return (
		<VStack>
			<Heading fontSize={headingBreaks}>
				ROADMAP
			</Heading>
			<Accordion defaultIndex={[1]} w='full' >
				<AccordionItem {...itemStyle} >
					<AccordionButton p={0} >
						<Heading {...headStyle} >
							FASE 1
						</Heading>
						<AccordionIcon color='#00D922'/>
					</AccordionButton>
					<AccordionPanel color='#b2c3d5' px={0} >
						Crear el Discord, montar página web, sacar por fases las variantes de los NFT
						{/* TODO add links */}
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem {...itemStyle} >
					<AccordionButton p={0} >
						<Heading {...headStyle} >
							FASE 2
						</Heading>
						<AccordionIcon color='#00D922' />
					</AccordionButton>
					<AccordionPanel color='#b2c3d5' px={0}>
						Conseguir una buena base de personas dentro de la comunidad, darnos a conocer, hacer colaboraciones con otros proyectos y seguir inovando
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem {...itemStyle} >
					<AccordionButton p={0} >
						<Heading {...headStyle} >
							FASE 3
						</Heading>
						<AccordionIcon color='#00D922' />
					</AccordionButton>
					<AccordionPanel color='#b2c3d5' px={0} >
						Aportar contenido y herramientas de inteligencia artificial para que nuestros usuarios se mantengan a la ultima en cuanto a todo lo que rodea esta nueva tecnologia
					</AccordionPanel>
				</AccordionItem>
				<AccordionItem {...itemStyle} >
					<AccordionButton p={0} > 
						<Heading {...headStyle} >
							FASE 4
						</Heading>
						<AccordionIcon color='#00D922' />
					</AccordionButton>
					<AccordionPanel color='#b2c3d5' px={0} >
						Levantar el Floor Price, abrir tienda para holders, distribuir 10.000 / 10.000 NFT y competir para ser una de las mejores y mas conocidas comunidades NFT
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
		</VStack>
	)
}
