import { 
	useState 
} from 'react'

import { 
	VStack,
	HStack,
	Text,
	Image,
	Flex,
	Heading,
	Center,
	Button,
	Box,
	Link
} from '@chakra-ui/react'

import { Stripe } from './Stripe'
import { 
	motion,
} from 'framer-motion'

import { AnimatedBg } from './AnimatedBg'
import { TextStripe } from './TextStripe/TextStripe'
import { Links } from './Links/Links.jsx'

import Marquee from 'react-fast-marquee'

export const HomePage = () => {
	
	const btnStyle = {
		as: motion.button,
		style: {
			borderRadius: 0,
			fontWeight: 'bold',
			outlineColor: 'yellow',
			outlineWidth: '1px'
		},
		whileHover: {
			scale: 1.25,
			backgroundColor: 'black',
			color: '#ff7',
			outlineColor: '#b2c3d5',
			outlineWidth: '5px',
			transition: { duration: 0.01 }
		},
		onHoverEnd: {
			transition: { duration: 0.01 }
		},
		bgColor: 'yellow',
		color: 'black',
		fontSize: '2xl',
		p: '30px'
	}

	const flexBreakpoints = ['column','column','row','row','row','row']
	const widthBreakpoint = ['100%','100%','50%','50%','50%','50%']
	const flexTextBreakpoints = ['90%', '65%', '90%', '50%', '40%', '40%']
	const mainFlexTextBreakpoints = ['90%', '65%', '90%', '60%', '60%', '40%']

	return (
		<VStack
			//h='150vh'
			w='100%'
		>
			<Stripe />
			<Image 
				src='/resources/name.webp' 
				py='75px'
				w={['75%','auto','auto','auto','auto','auto']}
			/>
			<Stripe reverse />
			<Flex
				direction={flexBreakpoints}
				justifyContent='center'
				align='center'
			>
				<Image 
					src='/pepes/main.webp'
					boxSize={['300px','300px','400px','500px','900px']}
				/>
				<Box
					justifyContent='center'
					align='center'
					color='yellow'
					w='50%'
					w={widthBreakpoint}
				>
					<VStack
						justifyContent='center'
						color='yellow'
						w={mainFlexTextBreakpoints}
					>
						<Heading 
							align='center'
							size={['md','md','md','md','xl']}
						>
							UN MEME CAPAZ DE POTENCIARTE
						</Heading>
						<Text 
							align='center'
							fontSize='lg'
							pt='10px'
							color='#b2c3d5'
						>
							Impulsamos a personas creativas y con vision como diseñadores, artistas, cantantes, programadores, GFX e inversores. 
						</Text>
						<Text
							align='center'
							fontSize='lg'
							pt='10px'
							color='#b2c3d5'
						>
							Centramos nuestra creatividad en la inteligencia artificial para hacer crecer tus negocios y proyectos
						</Text>
						<Box 
							w='full'
							align='center'
							p='30px'		
						>
							<Link
								href={`http://mint.${window.location.host}`}
								style={{ textDecoration: 'none' }}
							>
								<Button
									{...btnStyle}
								>
									BUY PEPE
								</Button>
							</Link>
						</Box>
					</VStack>
				</Box>
			</Flex>
			<TextStripe />
			<Flex
				direction={flexBreakpoints}
				justifyContent='center'
				w='full'
			>
				<Box
					justifyContent='center'
					align='center'
					color='yellow'
					w='50%'
					w={widthBreakpoint}
				>
					<VStack
						my='10%'
						w='30%' // TODO responsive
						w={['50%','50%','50%','50%','50%','30%']}
						w={flexTextBreakpoints}
					>
						<Heading 
							align='center'
							size={['md','md','md','md','xl']}
						>
							TECNOLOGIA IA AVANZADA
						</Heading>
						<Text 
							align='center'
							fontSize='lg'
							pt='10px'
							color='#b2c3d5'
						>
							Comunidad de creativos, inversores y entusiastas de la IA y la Blockchain
						</Text>
						<Text
							align='center'
							fontSize='lg'
							pt='10px'
							color='#b2c3d5'
						>
							Chat GPT Integrado
						</Text>
						<Text
							align='center'
							fontSize='lg'
							pt='10px'
							color='#b2c3d5'
						>
							Midjourney y herramientas IA
						</Text>
						<Text
							align='center'
							fontSize='lg'
							pt='10px'
							color='#b2c3d5'
						>
							Salas de exposición NFT
						</Text>
						<Text
							align='center'
							fontSize='lg'
							pt='10px'
							color='#b2c3d5'
						>
							Foros, tutoriales y guiuas
						</Text>
						<Text
							align='center'
							fontSize='lg'
							pt='10px'
							color='#b2c3d5'
						>
							Concursos y sorteos
						</Text>
						<Box 
							w='full'
							align='center'
							p='30px'
						>
							<Link 
								href='https://discord.gg/sarpr8nEKD'
								isExternal
								style={{
									textDecoration: 'none'
								}}
							>
								<Button
									{...btnStyle}
								>
									JOIN NOW {/* ADD DISCORD LOGO */}
								</Button>
							</Link>
						</Box>
					</VStack>
				</Box>
				<AnimatedBg />
			</Flex>
			<TextStripe reverse />
			<div id='links'>
			<Links />
			</div>
			<Box 
				w='full'
				bgColor='#eee600'
				align='center'
			>
				<Text color='black' bold>
					©2022 por PEPE LAMBO CLUB
				</Text>
			</Box>
		</VStack>
	)
}
