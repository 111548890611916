
import {
	Flex,
	Text,
	Spacer
} from '@chakra-ui/react'

export const AmountText = () => (
	<Flex
		direction='row'
		color='yellow'
		as='b'
		fontSize='2xl'
		w='full'
	>
		<Text>A</Text>
		<Spacer />
		<Text>M</Text>
		<Spacer />
		<Text>O</Text>
		<Spacer />
		<Text>U</Text>
		<Spacer />
		<Text>N</Text>
		<Spacer />
		<Text>T</Text>
	</Flex>
)
