import {
	HStack,
	Link,
	Button,
	Image,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Flex,
	Spacer,
	Icon,
	AspectRatio,
} from '@chakra-ui/react'

import { motion } from 'framer-motion'
import { HashLink } from 'react-router-hash-link'

import {
	HamburgerIcon,
	QuestionIcon,
	LinkIcon
} from '@chakra-ui/icons'
import { SiSolidity } from 'react-icons/si'

import { linkStyle, headerHeight, buttonStyle } from './DefaultHeader'

export const MobileHeader = () => {

	const menuItemStyle = {
		bgColor: 'black',
		color: 'white',
		_hover: {
			color: 'yellow'
		}
	}

	return (
		<Flex
			//h={['40px', '40px', '50px', '50px', '50px']}
			h={headerHeight}
			//p={5}
			bgColor='black'
			//pos='fixed'
			//zIndex={3}
			spacing='auto'
			align='center'
		>
			<Link
				to='/'
				{...linkStyle}
				ml={10}
			>
				<Button 
					{...buttonStyle}
				>
					Home
				</Button>
			</Link>
			<Spacer />
			<Menu
			>
			  	<MenuButton
					as={IconButton}
					aria-label='Options'
					icon={<HamburgerIcon />}
					variant='outline'
					color='white'
					//bgColor='red'
					w='100px'
					h='auto'
					style={{
						border: 'none',
						fontSize: '30px'
					}}
					_hover={{
						backgroundColor: 'black',
						color: 'yellow'
					}}
					_active={{
						backgroundColor: 'black'
					}}
			  	/>
			  	<MenuList
					zIndex={10}
					w='full'
					p={5}
					style={{
						borderRadius: 0,
						borderColor: '#b2c3d5',
						borderWidth: '5px'
					}}
					bgColor='black'
			  	>
					<Link
						href={`http://mint.${window.location.host}`}
						style={{ textDecoration: 'none' }}
					>
						<MenuItem 
							icon={<SiSolidity verticalAlign='text-top'/>}
							{...menuItemStyle}
						>
							Mint
						</MenuItem>
					</Link>
					<Link
						{...linkStyle}
						to='/about'
					>
						<MenuItem
							icon={<QuestionIcon verticalAlign='text-top'/>}
							{...menuItemStyle}
						>
							About
						</MenuItem>
					</Link>
					<MenuItem 
						icon={<LinkIcon verticalAlign='text-top'/>}
						{...menuItemStyle}
						as={HashLink}
						to='/#links'
						smooth
					>
						Links
					</MenuItem>
			  	</MenuList>
			</Menu>
		</Flex>
	)
}
