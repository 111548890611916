import { useEffect, useState } from 'react'
import { ethers } from 'ethers'

import {
	Flex,
	Spacer,
	Text,
} from '@chakra-ui/react'

import { 
	getProvider,
	contractAddr
} from './MintComponent'

export const readAbi = [
	'function whitelistSpotsAvailable(address addr) external view returns (uint256)'
]

export const Bill = ({ sliderVal, isConnected }) => {
	
	const [wlMints, setWlMints] = useState(0)

	const provider = new ethers.providers.AlchemyProvider(
		'matic',
		process.env.REACT_APP_ALCHEMY_API
	)
	const contract = new ethers.Contract(
		contractAddr,
		readAbi,
		provider
	)
	
	const userProvider = getProvider()
	
	useEffect(() => {
		if (userProvider == null) return
		userProvider.getSigner().getAddress().then(
			res => contract.whitelistSpotsAvailable(res).then(
				wls => setWlMints(wls.toNumber())
			)
		)
	}, [isConnected])
	
	const getPrice = () => {
		const abs = 5 * (sliderVal - wlMints)
		return abs < 0 ? 0 : abs
	}

	return (
		<Flex
			direction='row'
			w='100%'
			color='#b2c3d5'
		>
			<Text>{sliderVal}</Text>
			<Spacer />
			<Text>
				<Text as='span' color='yellow' >{getPrice()}</Text> MATIC 
				<Text as='span' color='#b2c3d566'> {wlMints === 0 ? null : `(${wlMints} FREE MINTS)`}</Text>
			</Text>
		</Flex>
	)

}
