
import Marquee from 'react-fast-marquee'
import {
	VStack,
	Heading
} from '@chakra-ui/react'

export const TextStripe = ({ reverse = false }) => {
	return (
		<VStack
			bgColor='yellow'
			color='black'
			w='full'
			py='10px'
		>
			<Marquee
				gradient={false}
				speed={10}
				style={{
					overflow: 'hidden',
				}}
				direction={reverse ? 'right' : 'left'}
			>
			<Heading
				size={['xl','xl','xl','xl','2xl','2xl',]}
			>
				/ EVENTOS DE LA COMUNIDAD // EVENTOS DE LA COMUNIDAD // EVENTOS DE LA COMUNIDAD // EVENTOS DE LA COMUNIDAD /
			</Heading>
			</Marquee>
		</VStack>
	)
}
