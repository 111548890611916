import {
	Modal,
 	ModalOverlay,
 	ModalContent,
 	ModalHeader,
 	ModalFooter,
 	ModalBody,
 	ModalCloseButton,
	Button,
	Text
} from '@chakra-ui/react'

export const InfoModal = ({ isOpen, onClose }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			size='xl'
		>
        	<ModalOverlay />
        	<ModalContent
				style={{
					borderRadius: 0,
					borderColor: 'yellow',
					borderWidth: '4px'
				}}
				bgColor='#000000ee'
				color='white'
				p={7}
			>
        	  <ModalHeader fontSize='2xl' color='#b2c3d5' as='b'>
				Mint en progreso
			  </ModalHeader>
        	  <ModalCloseButton />
        	  <ModalBody pb={6} fontSize='xl' align='center'>
				  Una vez firmes la transacción, mira en <br/>
				  <Text align='center' as='b' color='#ffffaa'>Opensea > Hidden</Text><br/>
				  tras un par de minutos. 
				  <Text fontSize='md' pt={6} align='left'>
					Nuestro contrato usa óraculos
				  	de Chainlink para asegurar que el proceso de mint
				  	sea descentralizado. Pero en consecuencia todos los NFTs
					se van directamente a hidden.
				  </Text>
        	  </ModalBody>
        	</ModalContent>
      	</Modal>
	)
}
