import { useRef, useState, useLayoutEffect, useEffect} from 'react'

import { 
	Box,
	Text,
	VStack,
	HStack,
	Image,
	Flex,
	Heading,
	Grid,
	GridItem,
	Spacer
} from '@chakra-ui/react'

import { headerHeight } from '../Header/DefaultHeader'
import { CollectionInfo } from './CollectionInfo'
import { Roadmap } from './Roadmap'

import img1 from '../samples/6.png'
import img2 from '../samples/10.png'
import img3 from '../samples/12.png'
import img4 from '../samples/7.png'
import img5 from '../samples/5.png'

const imgs = [
	img1, img2, img3, img4, img5
]

export const AboutPage = () => {
	
	const flexBreakpoints = ['column','column','column','row','row','row']
	const roadmapPadding = ['0%','5%','10%','0%','0%','0%']

	const aboutRef = useRef(null)
	const [ roadmapWidth, setRoadmapWidth ] = useState('full')
	
	const getWidth = () => aboutRef.current.offsetWidth
	
	const update = () => {
		const w = `${getWidth()}px`
		setRoadmapWidth([
			'full', 'full', 'full', w, w, w
		])
	}

	useEffect(update, [])

	useLayoutEffect(update, [])

	return (
		<VStack
			minH={`calc(100vh - ${headerHeight})`}
		>
			<HStack py={5} justifyContent='center'>
				{imgs.map(img => (
					<Image
						boxSize={['15%','15%','15%','10%','10%','10%',]}
						src={img}
					/>
				))}
			</HStack>
			<Flex
				direction={flexBreakpoints}
				py={5}
			>
				<Box 
					py={5}
					ref={aboutRef}
				>
					<CollectionInfo />
				</Box>
				<Box
					py={5}
					w={roadmapWidth}
					px={roadmapPadding}
				>
					<Roadmap />
				</Box>
			</Flex>
		</VStack>
	)
}
