import {
	HStack,
	Link,
	Button,
	Image
} from '@chakra-ui/react'

import { motion } from 'framer-motion'

import { HashLink } from 'react-router-hash-link'

export const headerHeight = '100px'

export const linkStyle = {
	as: HashLink,
	smooth: true,
	style: { textDecoration: 'none' }
}

export const buttonStyle = {
	as: motion.button,
	variant: 'link',
	fontSize: '30px',
	color: 'yellow',
	style: {
		fontWeight: 'bold',
		textDecoration: 'none'
	},
	whileHover: {
		scale: 1.1,
		color: '#663',
		cursor: 'pointer',
		transition: { duration: 0.01 }
	}
}

export const DefaultHeader = () => {
	return (
		<HStack
			//h={['40px', '40px', '50px', '50px', '50px']}
			h={headerHeight}
			justifyContent='center'
			p={5}
			bgColor='black'
			//pos='fixed'
			zIndex={3}
			spacing={90}
		>
			<Link
				to='/'
				{...linkStyle}
			>
				<Button 
					{...buttonStyle}
				>
					HOME
				</Button>
			</Link>
			<Link
				href={`http://mint.${window.location.host}`}
				style={{ textDecoration: 'none' }}
			>
				<Button 
					{...buttonStyle}
				>
					BUY PEPE
				</Button>
			</Link>
			<Link
				{...linkStyle}
				to='/about'
			>
				<Button 
					{...buttonStyle}
				>
					ABOUT
				</Button>
			</Link>
			<Link
				{...linkStyle}
				to='/#links'
			>
				<Button 
					{...buttonStyle}
				>
					LINKS
				</Button>
			</Link>
		</HStack>
	)
}
