import {
	VStack,
	Heading,
	Grid,
	GridItem,
	Text
} from '@chakra-ui/react'

import { LinkButtons } from '../Links/LinkButtons'

export const headingBreaks = ['2xl','4xl','4xl','4xl','4xl','4xl']
export const subHeadingBreaks = ['xl','3xl','3xl','3xl','3xl','3xl']
export const fontBreaks = ['md','md','xl','xl','xl','xl']

export const CollectionInfo = () => {
	return (
		<VStack>
			<Heading fontSize={headingBreaks}>
				PEPE LAMBO CLUB
			</Heading>
			<Grid
				templateRows='repeat(2, 1fr)'
				templateColumns='repeat(2, 1fr)'
				gap={7}
				p={7}
			>
				<GridItem align='right'>
					<Heading color='yellow' fontSize={subHeadingBreaks}>Total Supply</Heading>
					<Text color='#b2c3d5' fontSize={fontBreaks}>
						10.000 Digital tokens
					</Text>
				</GridItem>
				<GridItem>
					<Heading color='yellow' fontSize={subHeadingBreaks}>Contacto</Heading>
					<Text color='#b2c3d5' fontSize={fontBreaks}>
						Comunidad Discord 24h {/* TODO add link */}
					</Text>
				</GridItem>
				<GridItem align='right'>
					<Heading color='yellow' fontSize={subHeadingBreaks}>Mint</Heading>
					<Text color='#b2c3d5' fontSize={fontBreaks}>
		 		        5 MATIC primera semana {/* TODO add link */} 
					</Text>
				</GridItem>
				<GridItem>
					<Heading color='yellow' fontSize={subHeadingBreaks}>Fundado en</Heading>
					<Text color='#b2c3d5' fontSize={fontBreaks}>
						Septiembre 2022
					</Text>
				</GridItem>
			</Grid>
			<VStack id='links'>
				<Heading color='yellow'>Links oficiales</Heading>
				<LinkButtons />
			</VStack>
		</VStack>
	)
}
