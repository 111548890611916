import {
	useState,
	useEffect
} from 'react'

import {
	Box
} from '@chakra-ui/react'

export const AnimatedBg = () => {
	
	const [x, setX] = useState(0)
	const widthBreakpoint = ['100%','100%','50%','50%','50%','50%']

	setTimeout(() => {
		setX(x + 1)
	}, 10)

	return (
		<Box 
			w={widthBreakpoint}
			minHeight='300px'
			//bgColor='yellow'
			style={{
				backgroundImage: 'url(/pepes/mona.png)',
				backgroundPosition: 'center',
				backgroundSize: '50%',
				backgroundPositionX: x*(4/10),
				backgroundPositionY: (-x)*(2/10)
			}}
		>
		</Box>
	)
}
