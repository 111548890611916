import { useEffect, useState } from 'react'
import { ethers } from 'ethers'

import {
	Button,
	Text,
	Box,
	useToast,
} from '@chakra-ui/react'

import { motion } from 'framer-motion'

export const MintButton = ({ buttonAction, buttonText }) => {
	
	return (
		<>
		<Button
			onClick={buttonAction}
			as={motion.button}
			variant='outline'
			w='full'
			h='50px'
			color='#b2c3d5'
			whileHover='hovering'
			whileTap='clicking'
			style={{
				borderRadius: 0,
				borderColor: 'yellow',
				borderWidth: '3px'
			}}
			variants={{
				hovering: {
					backgroundColor: '#000',
					borderColor: '#b2c3d5',
					color: 'yellow',
					borderWidth: '1px',
				}
			}}
		>
			<Text
				as={motion.div}
				variants={{
					hovering: { scale: 1.25 },
					clicking: { scale: 0.75 }
				}}
			>
				{buttonText}
			</Text>
		</Button>
		</>
	)
}
