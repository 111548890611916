import {
	Box
} from '@chakra-ui/react'

import { Blob } from 'react-blob'

export const Blobs = () => (
	<Box 
		style={{ 
			position: 'relative'
		}}
	>
		<Blob 
			size='750px'
			style={{
				position: 'absolute',
				justifyContent: 'center',
				backgroundColor: '#b2c3d5',
				fontSize: '500px',
				zIndex: 0,
				opacity: 0.025,
				top: 'calc(50% + 125px)',
				left: 'calc(50% - 375px)',
			}}
		/>
		<Blob 
			size='500px'
			style={{
				position: 'absolute',
				justifyContent: 'center',
				backgroundColor: '#b2c3d5',
				fontSize: '500px',
				zIndex: 0,
				opacity: 0.025,
				top: 'calc(50% + 250px)',
				left: 'calc(50% - 250px)',
			}}
		/>
	</Box>
)
