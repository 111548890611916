import { 
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation
} from 'react-router-dom'

import { 
	ChakraProvider,
	Flex,
} from '@chakra-ui/react';


import { Header } from './components/Header/Header'
import { HomePage } from './components/HomePage'
import { AboutPage } from './components/AboutPage/AboutPage'
import { MintPage } from './components/MintPage/MintPage'

const CurrentPage = () => {
	const subdom = window.location.host.split('.')[0]
	if (subdom === 'mint') return (
		<Routes>
			<Route
				path='/'
				element={<MintPage />}
			/>
		</Routes>
	) 
	else return (
		<Flex
			backgroundColor='black'
			h='full'
			color='white'
			fontSize={['lg','xl','2xl','2xl','2xl']}
			direction='column'
		>
			<Header />
			<Routes>
				<Route
					path='/'
					element={<HomePage />}
				/>
				<Route
					path='/about'
					element={<AboutPage />}
				/>
			</Routes>
		</Flex>
	)
}

function App() {
  	return (
		<ChakraProvider>
			<Router>
				<CurrentPage />
			</Router>
		</ChakraProvider>
  	);
}

export default App;
