
import { 
	Icon,
	HStack,
	Link
} from '@chakra-ui/react'

import { motion } from 'framer-motion'

import { ReactComponent as OpenseaSvg } from './svgs/opensealogo.svg'
import { 
	FaDiscord,
	FaInstagram,
	FaTwitter,
	FaTwitch
}from 'react-icons/fa'

export const LinkButtons = () => {

	const iconContStyle = {
		as: motion.a,
		whileHover: {
			scale: 1.1,
		}
	}

	const iconStyle = {
		color: '#b2c3d5',
		fill: '#b2c3d5',
		boxSize: ['40px','50px','50px','50px','50px','70px'],
		_hover: {
			color: 'yellow',
			fill: 'yellow',
			cursor: 'pointer'
		}
	}

	const allIcons = [OpenseaSvg, FaDiscord, FaInstagram, FaTwitter, FaTwitch]
	const allLinks = [
		{
			icon: OpenseaSvg,
			uri: 'https://opensea.io/collection/pepe-lambo-club'
		},
		{
			icon: FaDiscord,
			uri: 'https://discord.gg/sarpr8nEKD'
		},
		{
			icon: FaInstagram,
			uri: 'https://www.instagram.com/ancapspain/'
		},
		{
			icon: FaTwitter,
			uri: 'https://twitter.com/PepeLamboClub'
		},
		{
			icon: FaTwitch,
			uri: 'https://www.twitch.tv/pepelamboclub'
		},
	]

	return (
		<>
			<HStack
				pt={5}
				spacing={5}
			>
				{allLinks.map(({ icon, uri }) => (
					<Link
						href={uri}
						isExternal
						{...iconContStyle} 
					>
						<Icon
							as={icon}
							{...iconStyle}
						/>
					</Link>
				))}
			</HStack>
			<Link
				color='#b2c3d5'
				fontSize='3xl'
				_hover={{
					color: 'yellow'
				}}
				style={{
					fontWeight: 'bold'
				}}
				href='https://oncyber.io/copepe'
				isExternal
			>
				SALA DE EXPOSICION NFT ONCYBER
			</Link>
		</>
	)
}
